import React from 'react';
import handleScrollTo from '../functions/handleScrollTo';

const AboutMePage = () => {
    return (
        <div id="aboutMe" className="spacer">
            <div className="content-container">
                <div className="section">
                    <h1 className="page-header__big page-header__big--aboutme">About me</h1> 
                    <div className="content-container__split content-container__split--background">                
                        <p className="text__indent">
                        I am an IT Specialist with 5 years of experience in IT as a Systems Engineer and Help Desk Technician. I graduated from Pace University in 2020 with a Bachelors in Computer Science.
                         I am a strong communicator with experience from teaching young students programming concepts, to providing technical support to customers at Help Desk.
                         I also excel in application management, performance data analysis, and have a knack for problem-solving.
                         Proficient in several programming languages, I am also a Microsoft Azure certified professional with a passion for development.
                        </p>
                        <button onClick={() => handleScrollTo('resume')} className="button button--aboutme">Next Section: Resume</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AboutMePage;