export default [{
    title: 'firetyper',
    name: 'Firetyper',
    listDescription: "Improve your typing skills!",
    pageDescription: "Improve your typing skills! A typing test with dynamic prompts for improving your typing ability, or for warming up. Intended for desktop use with a keyboard. Features: React.js, Node.js, Express.js, SASS, Webpack, Yarn, Babel, Heroku, Firebase",
    liveSite: 'https://firetyper.jwortiz.dev/',
    sourceCode: 'https://github.com/jameswortiz728/Firetyper'
}, {
    title: 'expenses-tracker',
    name: 'Expenses Tracker',
    listDescription: 'Take control of your expenses!',
    pageDescription: 'Take control of your expenses! Add, edit, or remove expenses. Search for expenses using filters based on amount, date created, and others. Mobile friendly. Features: React.js, Redux, Jest, Node.js, Express.js, SASS, Webpack, Yarn, Babel, Heroku, Firebase',
    liveSite: 'https://expenses-tracker.jwortiz.dev/',
    sourceCode: 'https://github.com/jameswortiz728/Expenses-Tracker'
}];